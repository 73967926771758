import MessageCard from '../MessageCard.vue';
import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import language from './messageLanguage'
export default defineComponent({
    name: "MessageReceiveList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj=reactive({
            pageListRef:null as any,
            canReceive:false,
            canView:false,
            pageList: {
                queryParam: {
                    modelFrom:'messageReceive'
                },
                modelComp: MessageCard,
                modelMethod: utils.Api.buildUrl("/message/pageData")
            }
        })
        //---------------------------computed------------
        const comboSelect=computed(()=>{
            return (params:any) => {
                let content=[] as any;
                switch (params) {
                    case 'receiveStatus':content=[{value:0,label:proxy.$t('message.not_sign')},{value:1,label:proxy.$t('message.sign')}]; break;
                }
                return content;
            }
        })
        //单条签收
        const singleReceiveHandle=(row:any)=>{
            dataObj.pageListRef.getTbInst().toggleRowSelection(row);
            doSignMsg(row["F_ID"]);
        }
        //批量签收
        const receiveHandle=()=>{
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                utils.Tools.info({message: proxy.$t('message.sign_tip')});
                return;
            }

            let ids ='',isOk=true;
            selectRows.forEach((item:any)=>{
                if(item.F_STATUS!=0){
                    isOk=false;utils.Tools.info({message:proxy.$t('message.batch_sign_tip')});
                }else{
                    ids = ids + item['F_ID'] + ",";
                }
            });
            if(isOk){
                ids = ids.trim().substr(0, ids.trim().length - 1);//去掉最后的逗号
                doSignMsg(ids);
            }
        }

        //签收
        const doSignMsg=(id:string)=>{
            utils.Tools.configBox({
                message:proxy.$t('message.sign_sure_tip'),
                sureFn: async ()=> {
                    let res = await utils.Api.messageSign({id:id});
                    if(res.result)  {
                        utils.Tools.success();
                        //重新加载列表页面，更新操作列和签收状态等
                        dataObj.pageListRef.queryHandler(true);
                        //top组件头部的消息数量需要重新加载
                        await dataObj.pageListRef.mainComp.topRef.utilInst.queryNotQsMsgNum();
                    }
                }
            });
        }

        //表格加载完毕事件
        const gridLoaded=(res:any)=>{
            dataObj.canReceive=res.canReceive;
        }

        return{
            ...toRefs(dataObj),comboSelect,singleReceiveHandle,receiveHandle,gridLoaded
        }
    },
    components: {}
});