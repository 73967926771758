export default {
    zh:{
        messageCard:'消息公告',
        message:{
            operate:'操作',
            edit:'编辑',
            delete:'删除',
            signDetails:'签收情况',
            msg_announcement:'通知公告',
            msg_online:'在线消息',
            msg_system:'系统消息',
            msg_corp:'指定单位',
            msg_role:'指定角色',
            msg_person:'指定个人',
            view:'查看',
            sign:'签收',
            not_sign:'未签收',
            sign_tip:'请选择要签收的消息',
            sign_sure_tip:'确定签收吗?',
            batch_sign_tip:'请全部选择未签收的消息进行批量签收',
            title_content_null_tip:'消息标题和消息内容不能为空',
            queryParam:{
                flag:'消息类型',
                flag_placeholder:'请选择消息类型',
                type:'消息类别',
                type_placeholder:'请选择消息类别',
                publishTime:'发布时间',
                publishTime_placeholder:'请选发布时间',
                title:'消息标题',
                title_placeholder:'请输入消息标题进行模糊匹配查询...',
                receiveStatus:'签收状态',
                receiveStatus_placeholder:'请选择签收状态',
            },
            columns:{
                F_PUBLISH_TIME:'发布时间',
                F_USER_NAME:'发布者',
                F_TITLE:'消息标题',
                F_FLAG_NAME:'消息类型',
                F_TYPE:'消息类别',
                F_STATUS_NAME:'签收状态',
            },
            signDialog:{
                title:'签收情况',
                F_CORP_NAME:'机构',
                F_USER_NAME:'签收人',
                F_DESC:'签收详情',
            },
            form:{
                flag:'消息类型',
                flag_placeholder:'请选择消息类型',
                view_msg:'预览消息',
                view_msg_tip:'如果不指定用户，将会往所有在线用户发送消息，预览不会发送，只有保存发送才会真正发送消息(该提示可关闭)',
                type:'消息类别',
                type_placeholder:'请选择消息类别',
                recCorpIds:'机构',
                recCorpIds_placeholder:'请选择机构',
                recRoleIds:'角色',
                recRoleIds_placeholder:'请选择角色',
                recUserIds:'指定用户',
                recUserIds_placeholder:'请指定用户',
                title:'消息标题',
                title_placeholder:'请输入消息标题',
                title_rule:'请输入消息标题',
                content:'消息内容',
                content_placeholder:'消息内容不能为空',
                publishTime:'发布时间',
                userId:'发布人',
            }
        }
    },
    en:{
        messageCard:'Message announcement',
        message:{
            operate:'operate',
            edit:'edit',
            delete:'delete',
            signDetails:'sign details',
            msg_announcement:'Notice announcement',
            msg_online:'Online messages',
            msg_system:'System Messages',
            msg_corp:'Specify organization',
            msg_role:'Specify roles',
            msg_person:'Specify individuals',
            view:'view',
            sign:'sign',
            not_sign:'not sign',
            sign_tip:'Please select the message to sign for',
            sign_sure_tip:'Are you sure to sign for it?',
            batch_sign_tip:'Please select all messages that have not been signed for batch signing',
            title_content_null_tip:'The message title and message content cannot be empty',
            queryParam:{
                flag:'message type',
                flag_placeholder:'please select message type',
                type:'message category',
                type_placeholder:'please select message category',
                publishTime:'Publish time',
                status_placeholder:'please Select Publish time',
                title:'title',
                title_placeholder:'Please enter the message title for fuzzy matching query...',
                receiveStatus:'sign status',
                receiveStatus_placeholder:'please select sign status',
            },
            columns:{
                F_PUBLISH_TIME:'Publish time',
                F_USER_NAME:'Publisher',
                F_TITLE:'Title',
                F_FLAG_NAME:'Type',
                F_TYPE:'Category',
                F_STATUS_NAME:'Status',
            },
            signDialog:{
                title:'Sign Details',
                F_CORP_NAME:'organization',
                F_USER_NAME:'Signed by',
                F_DESC:'Signing Details',
            },
            form:{
                flag:'message type',
                flag_placeholder:'please select message type',
                view_msg:'view message',
                view_msg_tip:'If you do not specify a user, a message will be sent to all online users. The preview will not be sent, and only after saving and sending will the message be actually sent (this prompt can be turned off)',
                type:'message category',
                recCorpIds:'organization',
                recCorpIds_placeholder:'please select organization',
                recRoleIds:'role',
                recRoleIds_placeholder:'please select organization',
                recUserIds:'Specify individuals',
                recUserIds_placeholder:'please Specify individuals',
                title:'title',
                title_placeholder:'please input title',
                title_rule:'please input title',
                content:'content',
                publishTime:'publish time',
                userId:'publish user',
            }
        }
    }
}